import Vue from 'vue'
import moment from 'moment-timezone'

var numeral = require('numeral');
var abbrivation = require('number-abbreviate')
var config = {
    currency: {
        country: null,
        symbol: '₱'
    },
    user: {
        role: null,
        admin: false
    },
    time_difference: 0,
    baseURL: null
}


moment.suppressDeprecationWarnings = true

Vue.filter('setCurrency', function (data) {

    if (data.country) {
        config.currency.country = data.country
    }
})

Vue.filter('setBaseURL', function (url) {
    if (url) {
        config.baseURL = url + '/'
    }

})

Vue.filter('getBaseURL', function (image) {
    return config.baseURL + image
})
Vue.filter('currency', function (amount, option, abs) {
    let number = 0
    if (amount) {
        number = numeral(amount).format('0.00')
    }
    if (abs) {
        number = Math.abs(number)
    }
    if (option) {
        number = '₱' + numeral(number).format('0,0.00')
    }
    return number
})

Vue.filter('currencyv2', function (amount, decimal) {
    let number = 0
    if (amount) {
        number = numeral(amount).format('00')
    }
    if (decimal) {
        number = numeral(amount).format('.00').split('.')[1]
    }
    return number
})

Vue.filter('getRole', function () {
    if (config.user.role) {
        return config.user.role
    }
})
Vue.filter('amount', function (amount) {

    let number = 0
    if (amount) {
        number = numeral(amount).format('0.00')
    }

    return number
})

Vue.filter('fullname', function (first, last) {
    return first + ' ' + last
})



Vue.filter('getCountry', function () {

    if (config.currency.country) {
        return config.currency.country
    } else {
        return null
    }
})


Vue.filter('abbrivation', function (amount) {
    let number = 0

    if (amount) {
        if (config.currency.convertion_amount) {
            amount = amount * config.currency.convertion_amount
        }
        number = numeral(amount).format('0.00')
    }
    return config.currency.symbol + abbrivation(number, 1)
})

Vue.filter('setTimezone', function (time_difference) {
    if (time_difference) {
        config.time_difference = numeral(time_difference).format('00')
    }
});

Vue.filter('getTimezone', function (option) {
    if (config.time_difference) {
        if (option) {
            return numeral(config.time_difference).value()
        } else {
            return config.time_difference
        }
    } else {
        return 0
    }
})

Vue.filter('checkInviterExpirationDate', function (date) {
    if (date) {
        date = date.replace(/\s/g, 'T') + 'Z'
    }
    let expiration = moment(date).add(1, 'Y')
    let today = moment()
    let status = moment(today).isAfter(expiration)
    return status

});
Vue.filter('SubscriptionDateFormat', function (date) {

    if (date) {
        date = date.replace(/\s/g, 'T') + 'Z'
        return moment(date).format('MMMM D, YYYY');
    }
});

Vue.filter('date', function (date, full) {
    if (date) {
        date = moment(date, 'YYYY-MM-DD HH:mm:ss')
        if (full) {
            return date.format('MMMM D, YYYY hh:mm A')
        } else {
            return date.format('MMMM D, YYYY')
        }

    } else {
        return moment().format('MMMM D, YYYY');
    }
});
Vue.filter('displayDate', function (date) {
    if (date) {
        return moment(date).format('MMMM D, YYYY hh:mm A')
    } else {
        return 'invalid date'
    }
});

Vue.filter('getDate', function (date) {
    if (date) {
        date = date.replace(/\s/g, 'T') + 'Z'
    }
    if (date) {
        return moment(date).format('Do')
    } else {
        return 'invalid date'
    }
});



Vue.filter('userStartofSubscription', function (date) {
    let today = moment.utc()
    if (date) {
        date = date.replace(/\s/g, 'T')
    }
    if (date) {
        let start = moment.utc(date)

        if (start < today) {
            return moment(today).format('YYYY-MM-DD HH:mm:ss')
        } else {
            return moment(start).format('YYYY-MM-DD HH:mm:ss')
        }
    }

});

Vue.filter('userStartofSubscriptionv2', function (date) {
    let today = moment.utc()
    if (date) {
        let start = moment.utc(date)
        if (start < today) {
            return moment.utc().format('YYYY-MM-DD HH:mm:ss')
        } else {
            return moment.utc(start).format('YYYY-MM-DD HH:mm:ss')
        }
    }

})

Vue.filter('userUnpaidSubscription', function (date) {
    if (date) {
        date = date.replace(/\s/g, 'T') + 'Z'
    }
    if (date) {
        let start = moment(date)
        return moment(start).format('YYYY-MM-DDTHH:mm:ssZ')
    } else {
        return 'invalid date'
    }
})

Vue.filter('trialDate', function (start_date, invited) {
    if (start_date) {
        start_date = start_date.replace(/\s/g, 'T') + 'Z'
    }
    let start = moment(start_date)
    let days = invited ? 30 : 10
    return moment(start).add(days, 'days').format('YYYY-MM-DDThh:mm:ssZ')
});

Vue.filter('companyBillingDatePackage', function (last_subscription, end, company_bill) {
    if (last_subscription) {

        let c_start = moment(last_subscription)
        let c_bill = moment(company_bill)
        // let c_end = moment(end)




        let base_m = moment(c_start).set('date', c_bill.format('DD'))

        let m = moment(c_start).add(1, 'M')
        let q = moment(c_start).add(3, 'M')
        let sa = moment(c_start).add(6, 'M')
        let a = moment(c_start).add(12, 'M')


        let data = {
            monthly: m.diff(base_m, 'days'),
            quarterly: q.diff(base_m, 'days'),
            semi_annual: sa.diff(base_m, 'days'),
            annually: a.diff(base_m, 'days'),
        }
        return data
    }

});
Vue.filter('billingDate', function (start, company_bill_date, type) {
    if (company_bill_date) {
        company_bill_date = company_bill_date.replace(/\s/g, 'T')
        start = start.replace(/\s/g, 'T')
    }
    if (start && company_bill_date && type) {

        let temp_admin = moment.utc(company_bill_date)
        let start_of_subscription = moment.utc(start)
        let end_of_subscription = null

        if (type == 'm') {
            end_of_subscription = start_of_subscription.add(1, 'month')
        }
        if (type == 'q') {
            end_of_subscription = start_of_subscription.add(3, 'month')
        }
        if (type == 'sa') {
            end_of_subscription = start_of_subscription.add(6, 'month')
        }
        if (type == 'a') {
            end_of_subscription = start_of_subscription.add(12, 'month')
        }

        let end_month = end_of_subscription.format('MM')
        let end_year = end_of_subscription.format('YYYY')
        let billing_day = temp_admin.format('DD')
        let join_bill = end_year + '-' + end_month + '-' + billing_day + 'T' + temp_admin.format('HH:mm:ss')

        if (moment.utc(join_bill).format('YYYY-MM-DD') == 'Invalid date') {
            end_of_subscription = moment.utc(end_year + '-' + end_month + '-' + temp_admin.endOf('month').format('DD') + 'T' + temp_admin.format('HH:mm:ss'))
        } else {
            end_of_subscription = moment.utc(join_bill)
        }

        return end_of_subscription.format('YYYY-MM-DD HH:mm:ss')

    }


});
Vue.filter('today', function () {

    return moment().utc().format('YYYY-MM-DD HH:mm:ss');

});

Vue.filter('apiDate', function (date) {
    if (date) {
        date = moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
        return date
    } else {
        return moment.utc().format('YYYY-MM-DD HH:mm:ss')
    }
});

Vue.filter('addDay', function (date, day) {

    if (date) {
        date = moment(date)
    }
    if (date && day) {
        date = date.add(day, 'days')
        return date.format('YYYY-MM-DDThh:mm:ssZ')
    }
});
Vue.filter('trialDay', function (day) {
    let today = moment.utc()
    return today.add(day, 'day').format('YYYY-MM-DD HH:mm:ss')
})
Vue.filter('todaynoTime', function () {
    return moment.utc().format('YYYY-MM-DD');
});

Vue.filter('subtractDay', function (date, day) {
    if (date) {
        date = date.replace(/\s/g, 'T') + 'Z'
    }
    if (date && day) {
        return moment(date).subtract(day, 'day').format('YYYY-MM-DD');
    }
});

Vue.filter('dueDate', function (date) {
    if (date) {
        date = date.replace(/\s/g, 'T') + 'Z'
    }
    return moment(date).format('MMMM Do, YYYY')
})
Vue.filter('unpaidBetweenDates', function (start, end) {

    let n_start = moment(start)
    let n_end = moment(end)

    return n_end.diff(n_start.format('YYYY-MM-DD'), 'days') + 1
})
Vue.filter('betweenDates', function (startDay, endDay) {

    let start = moment.utc(startDay)
    let end = moment.utc(endDay)
    return end.diff(start.format('YYYY-MM-DD'), 'days')
});

Vue.filter('formatNumber', function (n) {

    if (n) {
        return Number(n).toLocaleString();
    } else {
        return 0;
    }
});

Vue.filter('checkEndOfSubscription', function (end_date) {
    if (end_date) {
        end_date = end_date.replace(/\s/g, 'T') + 'Z'
    }
    let date = moment(end_date)
    let today = moment()

    if (date < today) {
        return true
    } else {
        return false
    }
});

Vue.filter('productDescriptionMaxString', function (string) {
    return string.length > 250 ? string.substring(0, 250) + '...' : string
});

Vue.filter('role', function (role) {
    let data = {
        SA: 'Owner',
        CA: 'Company Admin',
        M: 'Manager',
        RU: 'Staff'
    }
    let e_role = 'N/A'
    for (let i in data) {
        if (role == i) {
            e_role = data[i]
        }
    }
    return e_role
})

Vue.filter('productLink', function (name, link, user_company_id, company_id) {
    let p = name.toLowerCase().replace(/\s/g, '')
    let url = null

    if (link.substring(link.length - 1) == '/') {
        link = link.substring(0, link.length - 1)
    }

    else if (p == 'timeinpro') {
        url = link + '?token=' + localStorage.getItem("token") + '&user_company_id=' + user_company_id + '&settings=true&company_id=' + company_id
    }

    else if (p == 'elearnph') {
        url = link + '?token=' + localStorage.getItem("token") + '&user_company_id=' + user_company_id
    }
  
    else if (p == 'supportph') {
        url = link + '?token=' + localStorage.getItem("token") + '&user_company_id=' + user_company_id
    }

    // if (p == 'aetos financial') {
    //     url = link + '?token=' + localStorage.getItem("token") + '&user_company_id=' + user_company_id
    // }
    else{
        url = link + '?token=' + localStorage.getItem("token") + '&user_company_id=' + user_company_id 
    }
    
    return url
})

Vue.filter('firstDayOfWeek', function () {

    return moment().startOf('week').format('YYYY-MM-DD');
});

Vue.filter('lastDayofWeek', function () {
    let today = moment()
    return moment(today).add(1, 'days').format('YYYY-MM-DD');
})


Vue.filter('phone', function (phone) {

    if (phone) {
        let x = phone.replace(/\D/g, '').match(/(\d{0,4})(\d{0,3})(\d{0,4})/)
        phone = !x[2] ? x[1] : x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3].substring(0, 4) : '');
    } else {
        phone = 'N/A'
    }
    return phone
});

Vue.filter('telephone', function (telephone) {
    if (telephone) {
        let x = telephone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
        telephone = !x[2] ? x[1] : x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
    } else {
        telephone = 'N/A'
    }
    return telephone
})

Vue.filter('getSubscription', function (start, company, type) {



    start = start.replace(/\s/g, 'T')
    company = company.replace(/\s/g, 'T')


    let end_of_subscription_from_db = moment.utc(start)
    let end_of_subscription_from_db_copy = moment.utc(start)
    let temp_admin = moment.utc(start)

    let end_of_subscription = null
    let end_of_subscription_copy = null
    let minimum = false
    let static_of_day = null

    // check for today date
    if (moment.utc().format('YYYY-MM-DD') > end_of_subscription_from_db.format('YYYY-MM-DD')) {
        end_of_subscription_from_db = moment.utc()
        end_of_subscription_from_db_copy = moment.utc()
    }

    // get added month per subscription
    temp_admin = this.addMonth(temp_admin, type)
    end_of_subscription_from_db_copy = this.addMonth(end_of_subscription_from_db_copy, type)

    // static day for semi annual
    if (type == 'sa') {
        static_of_day = 183
    }

    // validate the date of end-of-subscription
    end_of_subscription = this.dateValidator(temp_admin, company)
    end_of_subscription_copy = this.dateValidatorCopy(end_of_subscription_from_db_copy, company)

    // compare today date to end-of-subscription
    if (end_of_subscription.format('YYYY-MM-DD') < moment.utc().format('YYYY-MM-DD')) {
        end_of_subscription = end_of_subscription_copy
    }

    // get day difference
    let day_difference = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')

    // get static day
    static_of_day = this.validateDayDifference(end_of_subscription, end_of_subscription_from_db, day_difference, type)




    if (type == 'm') {
        if (day_difference > 31 && day_difference < 92) {
            end_of_subscription = end_of_subscription_copy
            day_difference = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        }
    }
    if (type == 'q') {
        if (day_difference > 92 && day_difference < 181) {

            end_of_subscription = end_of_subscription_copy
            day_difference = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        }
    }

    if (type == 'sa') {
        if (day_difference > 182 && day_difference < 363) {
            end_of_subscription = end_of_subscription_copy
            day_difference = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        }
    }

    if (type == 'a') {
        if (day_difference > 363) {

            end_of_subscription = end_of_subscription_copy
            day_difference = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        }
    }

    // if user is less than a month renew user and change the date
    if (day_difference < 28) {
        if (moment.utc().format('YYYY-MM-DD') >= end_of_subscription_from_db.format('YYYY-MM-DD')) {
            end_of_subscription = this.addMonth(moment.utc(), type)
        } else {
            end_of_subscription = this.addMonth(end_of_subscription_copy, type)
        }
        day_difference = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        static_of_day = this.validateDayDifference(end_of_subscription, end_of_subscription_copy, day_difference, type)
    }

    if (day_difference < 15) {
        minimum = true
    }

    let data = {
        start: end_of_subscription_from_db.format('YYYY-MM-DD HH:mm:ss'),
        end: end_of_subscription.format('YYYY-MM-DD HH:mm:ss'),
        days: day_difference,
        total_days: static_of_day,
        minimum: minimum
    }

    return data
})

Vue.filter('addMonth', function (date, type) {
    if (type == 'm') {
        date.add(1, 'month')
    }
    if (type == 'q') {
        date.add(3, 'month')
    }
    if (type == 'sa') {
        date.add(6, 'month')
    }
    if (type == 'a') {
        date.add(12, 'month')
    }
    return date
})

Vue.filter('dateValidator', function (date, company) {
    let end_of_subscription = null
    let month = date.format('MM')
    let year = date.format('YYYY')
    let day = moment(company, 'YYYY-MM-DD HH:mm:ss').format('DD')
    let join_bill = year + '-' + month + '-' + day + 'T' + date.format('HH:mm:ss')

    if (moment.utc(join_bill).format('YYYY-MM-DD') == 'Invalid date') {
        end_of_subscription = moment.utc(year + '-' + month + '-' + date.endOf('month').format('DD') + 'T' + date.format('HH:mm:ss'))
    } else {
        end_of_subscription = moment.utc(join_bill)
    }

    return end_of_subscription
})

Vue.filter('dateValidatorCopy', function (date, company) {
    let end_of_subscription = null
    let copy_month = date.format('MM')
    let copy_year = date.format('YYYY')
    let day = moment(company, 'YYYY-MM-DD HH:mm:ss').format('DD')
    let copy_join_bill = copy_year + '-' + copy_month + '-' + day + 'T' + moment(company, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')


    if (moment.utc(copy_join_bill).format('YYYY-MM-DD') == 'Invalid date') {
        end_of_subscription = moment.utc(copy_year + '-' + copy_month + '-' + date.endOf('month').format('DD') + 'T' + date.format('HH:mm:ss'))

    } else {
        end_of_subscription = moment.utc(copy_join_bill)
    }

    return end_of_subscription
})


Vue.filter('subscriptionChecker', function (end_of_subscription) {
    if (end_of_subscription) {
        if (moment().format('YYYY-MM-DD HH:mm:ss') > moment(end_of_subscription, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')) {
            return true
        } else {
            return false
        }
    }
});


Vue.filter('checkAdminExpired', function (end_of_expiration) {
    let today = moment()
    let admin = moment(end_of_expiration)
    if (today.format('YYYY-MM-DD HH:mm:ss') > admin.format('YYYY-MM-DD HH:mm:ss')) {
        return true
    } else {
        return false
    }
})

Vue.filter('displayDatev2', function (date) {
    if (date) {
        date = date.replace(/\s/g, 'T')
        let time_number = config.time_difference * 60
        return moment.utc(date).utcOffset(time_number).format('MMMM DD, YYYY hh:mm A')
    }
})
Vue.filter('validated')
Vue.filter('validateDayDifference', function (end_of_subscription, end_of_subscription_from_db, day_difference, type) {
    let static_of_day = null

    if (type == 'm') {
        if (day_difference > 31 || day_difference < 28) {
            static_of_day = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        } else {
            static_of_day = day_difference
        }

    }

    if (type == 'q') {
        if (day_difference > 92 || day_difference < 89) {
            static_of_day = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        } else {
            static_of_day = day_difference
        }

    }

    if (type == 'sa') {
        if (day_difference > 183 || day_difference < 181) {
            static_of_day = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        } else {
            static_of_day = day_difference
        }

    }

    if (type == 'a') {
        if (day_difference > 366 || day_difference < 365) {
            static_of_day = 365
        } else {
            static_of_day = day_difference
        }

    }

    return static_of_day
})

Vue.filter('subscriptionCheckerForAdminAndUser', function (user, admin) {


    admin = admin.replace(/\s/g, 'T')
    user = user.replace(/\s/g, 'T')

    admin = moment.utc(admin)
    user = moment.utc(user)

    if (user.format('YYYY-MM-DD') >= admin.format('YYYY-MM-DD')) {
        return true
    } else {
        return false
    }

});








Vue.filter('getSubscriptionBackup', function (start, company, type, admin, admin_expired, if_admin, status) {

    let is_admin = 'no'
    let is_trial = null

    if (typeof if_admin === 'boolean' && if_admin === false) {
        is_admin = 'yes'

    }

    if (status && status == 'trial') {
        is_trial = 'yes'

    }

    start = start.replace(/\s/g, 'T')
    company = company.replace(/\s/g, 'T')
    admin = admin.replace(/\s/g, 'T')


    let end_of_subscription_from_db = moment.utc(start)
    let end_of_subscription_from_db_copy = moment.utc(start)
    let temp_admin = moment.utc(admin)


    let end_of_subscription = null
    let end_of_subscription_copy = null
    let minimum = false
    let static_of_day = null


    let dif = null





    if (moment.utc().format('YYYY-MM-DD') > end_of_subscription_from_db.format('YYYY-MM-DD')) {
        end_of_subscription_from_db = moment.utc()

    }

    if (type == 'm') {
        temp_admin.add(1, 'month')
        end_of_subscription_from_db_copy.add(1, 'month')

    }
    if (type == 'q') {
        temp_admin.add(3, 'month')
        end_of_subscription_from_db_copy.add(3, 'month')

    }
    if (type == 'sa') {
        temp_admin.add(6, 'month')
        end_of_subscription_from_db_copy.add(6, 'month')
        static_of_day = 183
    }
    if (type == 'a') {
        temp_admin.add(12, 'month')
        end_of_subscription_from_db_copy.add(12, 'month')

    }
    let admin_month = temp_admin.format('MM')
    let admin_year = temp_admin.format('YYYY')
    let billing_day = moment(company, 'YYYY-MM-DD HH:mm:ss').format('DD')
    let join_bill = admin_year + '-' + admin_month + '-' + billing_day + 'T' + temp_admin.format('HH:mm:ss')

    if (moment.utc(join_bill).format('YYYY-MM-DD') == 'Invalid date') {
        end_of_subscription = moment.utc(admin_year + '-' + admin_month + '-' + temp_admin.endOf('month').format('DD') + 'T' + temp_admin.format('HH:mm:ss'))
    } else {
        end_of_subscription = moment.utc(join_bill)
    }

    let copy_month = end_of_subscription_from_db_copy.format('MM')
    let copy_year = end_of_subscription_from_db_copy.format('YYYY')
    let copy_join_bill = copy_year + '-' + copy_month + '-' + billing_day + 'T' + temp_admin.format('HH:mm:ss')


    if (moment.utc(copy_join_bill).format('YYYY-MM-DD') == 'Invalid date') {
        end_of_subscription_copy = moment.utc(copy_year + '-' + copy_month + '-' + end_of_subscription_from_db_copy.endOf('month').format('DD') + 'T' + end_of_subscription_from_db_copy.format('HH:mm:ss'))

    } else {
        end_of_subscription_copy = moment.utc(copy_join_bill)
    }
    if (!admin_expired) {

        dif = moment.utc(admin).diff(end_of_subscription.format('YYYY-MM-DD'), 'days')

        end_of_subscription = moment.utc(admin)


        end_of_subscription_copy.set({
            hour: moment.utc(admin).format('HH'),
            minute: moment.utc(admin).format('mm'),
        })

        if (is_admin == 'no' || is_trial == 'yes') {
            if (type == 'm') {

                if (Math.abs(dif) <= 31) {
                    end_of_subscription = end_of_subscription_copy
                }
            }
            if (type == 'q') {

                if (Math.abs(dif) > 31 && dif < 180 || dif == 0) {
                    end_of_subscription = end_of_subscription_copy
                }
            }
            if (type == 'sa') {

                if (Math.abs(dif) > 180 && dif < 360 || dif == 0) {
                    end_of_subscription = end_of_subscription_copy
                }
            }
            if (type == 'a') {

                if (Math.abs(dif) > 360 || dif == 0) {
                    end_of_subscription = end_of_subscription_copy
                }
            }
        }

    }


    let day_difference = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')

    if (type == 'm') {
        if (day_difference > 31 || day_difference < 28) {
            static_of_day = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        } else {
            static_of_day = day_difference
        }

        if (day_difference > 31 && day_difference < 92) {
            end_of_subscription = end_of_subscription_copy
            day_difference = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        }
    }

    if (type == 'q') {
        if (day_difference > 92 || day_difference < 89) {
            static_of_day = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        } else {
            static_of_day = day_difference
        }
        if (day_difference > 92 && day_difference < 181) {

            end_of_subscription = end_of_subscription_copy
            day_difference = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        }
    }

    if (type == 'sa') {
        if (day_difference > 183 || day_difference < 181) {
            static_of_day = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        } else {
            static_of_day = day_difference
        }
        if (day_difference > 182 && day_difference < 363) {
            end_of_subscription = end_of_subscription_copy
            day_difference = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        }
    }

    if (type == 'a') {
        if (day_difference > 366 || day_difference < 365) {
            static_of_day = 365
        } else {
            static_of_day = day_difference
        }
        if (day_difference > 363) {

            end_of_subscription = end_of_subscription_copy
            day_difference = end_of_subscription.diff(end_of_subscription_from_db.format('YYYY-MM-DD'), 'days')
        }
    }



    if (day_difference < 15) {
        minimum = true
    }
    let data = {
        start: end_of_subscription_from_db.format('YYYY-MM-DD HH:mm:ss'),
        end: end_of_subscription.format('YYYY-MM-DD HH:mm:ss'),
        days: day_difference,
        total_days: static_of_day,
        minimum: minimum
    }

    return data
})

Vue.filter('getSubsPlanSubscription', function (end, type) {

    var end_of_subscription = moment.utc(end)
    if (type == 'm') {
        end_of_subscription.add(1, 'month')
    }
    if (type == 'q') {
        end_of_subscription.add(3, 'month')
    }
    if (type == 'sa') {
        end_of_subscription.add(6, 'month')
    }
    if (type == 'a') {
        end_of_subscription.add(12, 'month')
    }


    return end_of_subscription.format('YYYY-MM-DD HH:mm:ss')

})