<template>
  <div>
    <v-overlay :value="over.show" opacity="1" color="white" class="text-center" :z-index="3" >
      <v-progress-circular
        indeterminate
        color="#00919B"
        size="64"
      >
     
      </v-progress-circular>
       <p class="text-center black--text mt-2 text-center">{{ over.message }} </p>
    </v-overlay>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      flat
      class="hidden-md-and-up" >
    <div>
    <v-img
          height="56"
          src="@/assets/whitelogo.png"
          @click="home"
        ></v-img>
    </div>
      <v-list dense>
          <v-list-item link to="/"   class="nav-items mb-0 font-weight-medium caption">
            <v-list-item-icon>
              <v-icon   >mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium"
                >Home</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item link  to="/product"  class="nav-items mb-0 font-weight-medium caption">
            <v-list-item-icon>
              <v-icon  >mdi-cart</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium"
                >Products</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/support" class="nav-items mb-0 font-weight-medium body1">
            <v-list-item-icon>
              <v-icon  >mdi-headset</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title  class="font-weight-medium"
              
                >Support</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        
         
          <v-list-item link  @click="signin"  class="nav-items  font-weight-medium">
            <v-list-item-icon>
              <v-icon >mdi-login-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title  class="font-weight-medium body-2"
              
                >Sign in</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
       
      </v-list>
   
    </v-navigation-drawer>

    <v-app-bar class="header" elevate-on-scroll app>
      <v-container>

        <v-row align="center">
          <v-icon
            class="hidden-md-and-up mr-3"
            @click="drawer = !drawer"
          > mdi-menu </v-icon>
          <div class="">
            <v-img
              lazy-src=""
              max-width="170"
              src="@/assets/etousoft.png"
              @click="home"
              v-if="!drawer"
              class="logo"
            ></v-img>
          </div>
          <v-spacer> </v-spacer>
          <v-btn
            text
            small
            @click="go('/')"
            class="ml-6 hidden-sm-and-down"
            active-class="active"
            >Home</v-btn
          >
          <v-btn
            text
            small
            @click="go('/product')"
            class="ml-6 hidden-sm-and-down"
            >Products</v-btn
          >
          <v-btn
            text
            small
            @click="go('/support')"
            class="ml-6 hidden-sm-and-down"
            >Support</v-btn
          >
        
          <v-btn
            text
            small
            @click="signin"
            class="ml-6 hidden-sm-and-down"
            >Sign in</v-btn
          >
 
        </v-row>
          </v-container>
    </v-app-bar>


  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: null,
      items: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" },
      ],
      over : {
        show : true,
        message : 'Loading...',
      },
      countDown : 0
    };
  },
  created()
  {
    if(this.$route.name != 'verify')
    {
      this.over.show = true
    }else{
      this.over.show = false
    }
    if(this.over.show)
    {
      this.countDown = 2
    }
  },
  watch : {
    countDown: {
      handler(value) {

          if (value > 0) {
              setTimeout(() => {
                  this.countDown--
                  if(this.countDown == 0)
                  {
                    this.over.show = false
                  }
              }, 1000);
          }

      },
      immediate: true // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    go(url)
    {
      this.$router.push({path : url})
    },
    signin() {
      window.location.href = this.$config.login;
    },
    home(){
      this.$router.push({path: '/'})
    }
  },
};
</script>
<style scoped>
.nav-items {
  text-decoration: none;
  margin-top: 10px;
}
#btn {
  text-transform: none;
}
.logo{
  cursor: pointer;
}
.header{
  z-index: 2;
}
</style>