import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        meta: {
            title: 'Easy to Use Software - Etousoft ',


        },
        component: () =>
            import ('../views/Home.vue')
    },
    {
        path: '/etousoftaccount',
        name: 'HelpCenter',
        meta: {
            title: 'Help Center - Etousoft',
        },
        component: () =>
            import ('../views/HelpCenter.vue')
    },
    {
        path: '/commitment',
        name: 'Commitment',
        meta: {
            title: 'Commitment - Etousoft',
        },
        component: () =>
            import ('../views/commitment.vue')
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        meta: {
            title: 'Privacy Policy - Etousoft',
        },
        component: () =>
            import ('../views/Privacy.vue')
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsAndCondition',
        meta: {
            title: 'Terms and Conditions - Etousoft',
        },
        component: () =>
            import ('../views/Terms.vue')
    },
    {
        path: '/cant-signin',
        name: 'CantSignin',
        meta: {
            title: 'FAQs - Etousoft',

        },
        component: () =>
            import ('../views/CantSignin.vue')
    },
    {
        path: '/product',
        name: 'Product',
        meta: {
            title: 'Products - Etousoft',
        },
        component: () =>
            import ('../views/Product.vue')
    },
    {
        path: '/support',
        name: 'Support',
        meta: {
            title: 'Support - Etousoft',
        },
        component: () =>
            import ('../views/Support.vue')
    },
    {
        path: '/faqs',
        name: 'faqs',
        meta: {
            title: 'Frequently Ask Questions - Etousoft',
        },
        component: () =>
            import ('../views/faq.vue')
    },
    {
        path: '/verify',
        name: 'verify',
        meta: {
            title: 'User Verification - Etousoft',
        },
        component: () =>
            import ('../views/Auth/Verify.vue')
    },
    {
        path: '/account/register-company',
        name: 'RegisterCompany',
        meta: {
            title: 'Create your own company - Etousoft',
            access: {
                admin: true,
                login: true
            }
        },
        component: () =>
            import ('../views/Account/RegisterCompany.vue')
    },
    {
        path: '/logout',
        name: 'Logout',
        meta: {
            title: 'Logging Out - Etousoft',
            access: {
                login: true
            }
        },
        component: () =>
            import ('../views/Auth/Logout.vue')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
            title: 'Dashboard - Etousoft',
            access: {
                admin: false,
                login: true
            }
        },
        component: () =>
            import ('../views/Account/Dashboard.vue')
    },
    {
        path: '/account',
        name: 'Account',
        meta: {
            title: ' Account - Etousoft',
            access: {
                admin: false,
                login: true
            }
        },
        component: () =>
            import ('../views/Account/Account.vue')
    },
    {
        path: '/account/affiliate/earnings',
        name: 'Earning',
        meta: {
            title: 'Earnings - Etousoft',
            access: {
                admin: false,
                login: true
            }
        },
        component: () =>
            import ('../views/Account/Earning.vue')
    },
    {
        path: '/account/yourinfo',
        name: 'YourInfo',
        meta: {
            title: 'My Information - Etousoft',
            access: {
                admin: false,
                login: true
            }
        },
        component: () =>
            import ('../views/Account/YourInfo.vue')
    },
    {
        path: '/account/orderhistory',
        name: 'OrderHistory',
        meta: {
            title: 'Transactions and Product Subscriptions - Etousoft',
            access: {
                admin: true,
                login: true
            }
        },
        component: () =>
            import ('../views/Account/OrderHistory.vue')
    },
    {
        path: '/account/managepayment',
        name: 'ManagePayment',
        meta: {
            title: 'Payment - Etousoft',
            access: {
                login: true,
                admin: false
            }
        },
        component: () =>
            import ('../views/Account/ManagePayment.vue')
    },
    {
        path: '/account/servicessubscription',
        name: 'ServicesSubscription',
        meta: {
            title: 'Your Subscribe Products and Featured Products- Etousoft',
            access: {
                login: true,
                admin: false
            }
        },
        component: () =>
            import ('../views/Account/ServicesSubscription.vue')
    },
    {
        path: '/account/subscribeproduct/:id',
        name: 'SubscribeProduct',
        meta: {
            title: 'Subscribing Etousoft Product - Etousoft',
            access: {
                login: true,
                admin: true
            }
        },
        component: () =>
            import ('../views/Account/SubscribeProduct.vue')
    },
    {
        path: '/account/mycompanies',
        name: 'MyCompanies',
        meta: {
            title: 'Affilliated Companies - Etousoft',
            access: {
                login: true,
                admin: false
            }
        },
        component: () =>
            import ('../views/Account/MyCompanies.vue')
    },
    {
        path: '/account/manage/company',
        name: 'Manage-Company',
        meta: {
            title: 'Company Management - Etousoft',
            access: {
                login: true,
                admin: true
            }
        },
        component: () =>
            import ('../views/Account/Manage.vue')
    },
    {
        path: '/account/discover',
        name: 'Discover',
        meta: {
            title: 'Discover - Etousoft',
        },
        component: () =>
            import ('../views/Account/Discover.vue')
    },
    {
        path: '/account/security',
        name: 'Security',
        meta: {
            title: 'Account Security - Etousoft',
            access: {
                admin: false,
                login: true
            }
        },
        component: () =>
            import ('../views/Account/Security.vue')
    },
    {
        path: '/product/:id',
        name: 'ProductDetail',
        meta: {
            title: 'Product Information - Etousoft',
        },
        component: () =>
            import ('../views/Account/Product.vue')
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        meta: {
            title: 'Page is under maintenance - Etousoft',
            access: {
                public: true
            }
        },
        component: () =>
            import ('../views/maintenance.vue')
    },
    {
        path: '/company/verify',
        name: 'CompanyVerify',
        meta: {
            title: 'Company Verification - Etousoft',
            access: {
                public: true
            }
        },
        component: () =>
            import ('../views/Auth/CompanyVerify.vue')
    },
    {
        path: '/*',
        name: '404',
        meta: {
            title: 'Page not found',
            access: {
                public: true
            }
        },
        component: () =>
            import ('../views/404.vue')
    },
   
]

const router = new VueRouter({
    routes,
    mode: 'history',

    scrollBehavior() {
        return { x: 0, y: 0 };
    }
})

export default router