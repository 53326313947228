<template>
  <div>
    <v-menu bottom  rounded offset-y transition="slide-y-transition">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" class="btn hidden-sm-and-down" aria-label="profile" :disabled="user ? false : true" >
          <v-avatar color="white" outlined size="26" elevation-10 >
            <span v-if="user && user.image">
              <v-img :src="user.image"></v-img>
              </span>
              <v-img src="@/assets/etousoft-logo.png" v-else></v-img>
          </v-avatar>
        </v-btn>
      </template>
        <v-list dense>
            <v-list-item class="justify-center border-bottom">
              <v-list-item-avatar  v-if="user">
                <router-link to="/account/yourinfo" aria-label="your info">
                  <v-avatar
                    color="#00919B"
                    size="24"
                    class="p-3 black--text font-weight-bold" >
                  {{ getInitials()  }}
                  </v-avatar>
                </router-link>
              </v-list-item-avatar>
              <div v-if="user">
                <v-list-item-title class="font-weight-bold">
                  {{ user.first_name }} {{ user.last_name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </div>
              <div v-else >
                <v-list-item-title>
                  <h6 class="font-weight-medium mt-4 mb-0">
                    Loading...
                  </h6>
                </v-list-item-title>
                <v-list-item-subtitle>Loading...</v-list-item-subtitle>
              </div>
            </v-list-item>
            <v-list-item link  @click="go('/dashboard')" >
              <v-list-item-icon> <v-icon> mdi-apps </v-icon></v-list-item-icon>
                <v-list-item-title >
                  Dashboard
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="go('/account')" >
              <v-list-item-icon> <v-icon> mdi-account-cog </v-icon> </v-list-item-icon>
              <v-list-item-title> Manage Account </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="go('/logout')"  >
              <v-list-item-icon><v-icon> mdi-logout-variant </v-icon></v-list-item-icon>
              <v-list-item-title > Sign Out </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
    <div class="hidden-md-and-up">
    
      <v-row class=" mt-1 mx-0">
        <v-col cols="3" class="text-center">
          <div class="mx-auto p-0">
            <router-link to="/account/yourinfo" aria-label="your info">
              <v-avatar   size="45" >
                <span v-if="user && user.image">
                  <v-img :src="user.image"></v-img>
                </span>
                <v-img class="bg-white" src="@/assets/etousoft-logo.png" v-else>
                </v-img>
              </v-avatar>
            </router-link>
          </div>
        </v-col>
        <v-col cols="9" class="">
          <p class="subtitle-2 mb-0">
              {{ user ? user.first_name +' '+ user.last_name :  'Loading..' }}
          </p>
          <small class="d-block caption  text-truncate mt-0"> {{ user ? user.email : 'Loading...' }} </small>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  props : ['user'],
  data: () => ({
    me: null,
  }),
  watch: {
    user: function(data)
    {

      if(data)
      {
        this.me = data
      }
    }
  },
  methods : {
    getInitials() {
      if(this.me)
      {
        let f = this.me.first_name.substring(0,1)
        let l = this.me.last_name.substring(0,1)
        return f.toUpperCase()+l.toUpperCase()
      }else{
        return "ES"
      }
      
    },
    go(url)
    {
      this.$router.push( {path : url})
    }
  },
};
</script>

<style scoped>
.btn {
  text-transform: none;
}
a:hover{
  text-decoration: none !important;
}
.v-list .v-list-item--active .v-list-item__title span{
  color: #9B0A00!important;
}
.v-list-item-group .v-list-item--active{
   background-color: #FF978F!important; 
}
</style>

