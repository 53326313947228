


import Vue from 'vue'



import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import  './plugins/axios'
import LoadScript from 'vue-plugin-load-script';
import './helpers/Filters'
import { User } from './helpers/User'
import country from 'country-list-js'
export const bus = new Vue();



Vue.use(LoadScript);
Vue.use(require("moment"))
Vue.prototype.$user = User
Vue.prototype.$countries = country


Vue.config.productionTip = false

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || "Easy to Use Sofware - Welcome to Etousoft";
    });
});

new Vue({
    router,
    vuetify,
    locale: 'fr',
    render: h => h(App),
    watch: {
        '$route': function() {
            if (this.$route.meta.access) {
                this.checkLogin(this.$route.meta.access)
            }
        }
    },
    methods: {
        checkLogin(access) {
            if (access.login) {
                if (!this.$user.SuccessLogin()) {
                    this.$router.push({ name: "Home" }).catch(() => {});
                }
            }
        },
    }
}).$mount('#app')