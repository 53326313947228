<template>
  <div>
    <v-container >
      <div class="">
        <h2>Help with etousoft account</h2>
       
          <router-link to="/etousoftaccount" id="help" class=" mb-2"
            >What is etousoft account ?</router-link >
          <br>
      
          <router-link to="/cant-signin"  id="help"  class=" mb-2" v-if="!me"
            >Help for when you can't sign in
          </router-link>
        
      </div>
    </v-container>

    <v-footer padless class="mt-4 border-top" dark >
      <v-container>
         <v-row class="mt-3" >
            <v-col cols="12" lg="4" md="6">
              <p class="h6 text-uppercase">about us</p>
              <p class="text-secondary  " >Etousoft is a company that creates productivity and management software to help
                 entrepreneurs grow their business. 
                Our flagship software is the <a href="https://timeinpro.com" class="font-weight-bold"> TimeinPRO</a>, where companies, who are in a remote or hybrid work environment, can monitor their employees’ activities. 
                Our goal is to help individuals improve their productivity and achieve a better work-life balance. </p>
            </v-col>

            <v-col cols="12" lg="2" md="6" class="" v-if="!me">
              <p class="subtitle-2 text-uppercase">quick links</p>
              <router-link to="/product" class="text-secondary d-block mb-1 over-p"  aria-label="products" >
                Products
              </router-link>
              <router-link to="/support" class="text-secondary d-block mb-1 over-p"   aria-label="Suppoert" >
                Support
              </router-link>
              <a @click="signin" class="text-secondary d-block mb-1 over-p"   aria-label="Sign in" >
                Sign-in
              </a>
              <router-link to="/" class="text-secondary d-block mb-1 over-p"   aria-label="Home" >
                Home
              </router-link>
            </v-col>
            <v-col cols="12" lg="2" md="6"  v-else>
              <p class="subtitle-2 text-uppercase">quick links</p>
              <router-link to="/account/servicessubscription" class="text-secondary d-block mb-1 over-p"  aria-label="subscription" >
                Subcriptions  
              </router-link>
              <router-link to="/account/yourinfo" class="text-secondary d-block mb-1 over-p"   aria-label="your info" >
                My Info
              </router-link>
              <router-link to="/account/mycompanies"  class="text-secondary d-block mb-1 over-p"  aria-label="company" >
                Company
              </router-link>
              <router-link to="/dashboard" class="text-secondary d-block mb-1 over-p"  aria-label="dashboard" >
                Dashboard
              </router-link>
            </v-col>

             <v-col cols="12" lg="2" md="6" class="">
              <p class="subtitle-2 text-uppercase">follow us</p>
              <v-btn icon text outlined class="mr-3 mb-3" aria-label="facebook" @click="goto('https://web.facebook.com/etousoft/')" >
                <v-icon size="20" color="grey" > mdi-facebook </v-icon>
              </v-btn>
              <!-- <v-btn icon text outlined class="mr-3 mb-3" aria-label="messenger">
                <v-icon size="20" color="grey" > mdi-facebook-messenger </v-icon>
              </v-btn>
              <v-btn icon text outlined class="mr-3 mb-3" aria-label="youtube">
                <v-icon size="20" color="grey" > mdi-youtube </v-icon>
              </v-btn>
              <v-btn icon text outlined class="mr-3 mb-3" aria-label="twitter">
                <v-icon size="20" color="grey" > mdi-twitter </v-icon>
              </v-btn>
              <v-btn icon text outlined class="mr-3 mb-3" aria-label="instagram">
                <v-icon size="20" color="grey" > mdi-instagram</v-icon>
              </v-btn>
              <v-btn icon text outlined class="mr-3 mb-3" aria-label="linkedin">
                <v-icon size="20" color="grey" > mdi-linkedin </v-icon>
              </v-btn> -->
            </v-col>
            <v-col cols="12" lg="3" md="6" class=" mb-4">
              <p class="subtitle-2 text-uppercase">contacts</p>
              <p class="text-secondary mb-2">
                <v-icon color="grey" size="20" class="mr-2 " > mdi-email-outline </v-icon>
                sales@etousoft.com
              </p>
              <!-- <p class="text-secondary mb-2">
                <v-icon color="grey" size="20" class="mr-2 " > mdi-phone </v-icon>
                (022) 234 3321
              </p> -->
              <p class="text-secondary mb-2 d-flex align-start">
                <v-icon color="grey" size="20" class="mr-3 mt-1" > mdi-map-marker-outline </v-icon>
                6F I-Care Building San Lorenzo, Makati City, 1223 Metro Manila Philippines
              </p>
            </v-col>
  
          
          </v-row>
          <div class="border-top pt-4">
            <div>
              <small class="text-right">
                {{ new Date().getFullYear() }} — <strong>Etousoft</strong> -
                 <span class="caption grey--text " v-if="me && me.country"> {{ me.country }} - </span>
              </small>
              <span class="caption grey--text mr-4">All Rights Reserved</span>
             
              <div class="hidden-md-and-up">
                <router-link to="/privacy-policy" class="caption grey--text mr-4 d-block">Privacy Policy</router-link>
                <router-link to="/terms-and-conditions" class="caption grey--text  mr-4  d-block">Terms and Conditions</router-link>
              </div>
              <span class="hidden-sm-and-down">
                <router-link to="/privacy-policy" class="caption grey--text mr-4 ">Privacy Policy</router-link>
                <router-link to="/terms-and-conditions" class="caption grey--text  mr-4  ">Terms and Conditions</router-link>
              </span>
              
            </div>
          </div>
      </v-container>
        
    </v-footer>
  </div>
</template>

<script>
export default {
  props: ['myInfo'],
  data() {
    return  {
      login : false,
      me : null,
    }
  },
  watch : {
    myInfo : function(val)
    {
      if(val)
      {
        this.me = val
      }else{
        this.me = null
      }
      
    }
  },
  methods : {
    signin()
    {
      window.location.href = this.$config.login;
    },
    goto(url)
    {
      window.open(url)
    }

  }
}

</script>

<style scoped>
#help {
  text-decoration: none;
}
p, .over-p{
  font-size: 15px!important;
}

</style>